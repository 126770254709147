import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebaseConfig';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import './login.css';
import { set, ref, push } from 'firebase/database';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [message, setMessage] = useState("");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        const email = user.email;
        const currentTime = new Date().toString();
        set(ref(db, `users/${user.uid}/${currentTime}`), {
          email: email,
        })
        console.log("User logged in:", email, "at", currentTime);
        navigate("/chatbot");
      }
    });
    return unsubscribe;
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log("success");
        navigate("/chatbot");
      })
      .catch((error) => {
        setMessage("Feil e-post eller passord");
        console.error("Feil e-post eller passord", error);
        setAlertType("error");
        setAlertOpen(true);
      });
  };
  
  
  const handleAlertClose = () => {
    setTimeout(() => {
      setAlertOpen(false);
    }, 5000);
  };

  return (
    <div className="supportai__login">
      <div className='supportai__login-intro'>
        <h1>Hei!👋</h1>
        <p>Logg inn for å få tilgang.</p>
      </div>
      <div className='supportai__login-form'>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            id="email"
            placeholder="E-post"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
            />
          <input
            type="password"
            id="password"
            placeholder="Passord"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            required
            />
          <button type="submit">Logg inn</button>
        </form>
      </div>
        {alertOpen && 
        <Alert variant="filled" open={alertOpen} onClose={handleAlertClose} severity={alertType}>
          {alertType === "success" ? "File Uploaded Successfully!" : 
           alertType === "error" ? 
             <>
               <AlertTitle>Error</AlertTitle>
                {message}
              </>
            : "Error Occured while uploading file"}
          </Alert>
        }
    </div>
  );
};

Login.propTypes = {};

export default Login
