import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Login, Chatbot } from '../components';
import PrivateRoute from './PrivateRoute';

const MainRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path='/' element={<PrivateRoute/>}>
          <Route exact path='/chatbot' element={<Chatbot/>}/>
        </Route>  
      </Routes>
    </Router>
  );
};

export default MainRoutes;
