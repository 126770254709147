import React, { useState, useRef, useEffect } from 'react';
import "./chatbot.css";
import data from './data/data.json';
import udir from "../../assets/udirbakgrunn.png"
import { TypingAnimation } from '../../components';

const Chatbot = () => {
  const [error, setError] = useState('');
  const [input, setInput] = useState('');
  const [chatLog, setChatLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const supportaiBodyRef = useRef(null);
  const messageRef = useRef(null);
  const [isOpen, setIsOpen] = useState(true);

  const scrollToBottom = () => {
    const supportaiBody = document.querySelector('.supportai-body');
    supportaiBody.scrollTop = supportaiBody.scrollHeight;
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatLog]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Store the user's input in a separate state variable
    const userInput = input;

    // Clear the input field
    setInput("");

    setChatLog([...chatLog, {user: "me", message: userInput}])
    
    const nameOfSystem = `Elevundersøkelsen`;
    const noanswer = `If you cant find the answer in ${data.allDocuments.content} type: "Beklager, jeg kan ikke svare på dette. 
    Vennligst prøv å omformulere spørsmålet ditt" `

    const prompt = `I want you to act as a support agent, your name is SupportAI. Your purpose is to answer basic questions from customers in an easy to understand way. 
                    Stay nice and helpful throughout the conversation.
                    Based on the ${data.allDocuments.content}, only answer the question shortly, 
                    all questions you recieve are related to ${nameOfSystem}, ${noanswer}
                    ${event.target.elements.prompt.value}`
    const API_KEY = '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';

    setIsLoading(true);

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'User-Agent': 'supportai/1.0.0',
        'Authorization': `Bearer ${API_KEY}`,
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [{"role": "user", "content": prompt}],
        temperature: 0.2,
        max_tokens: 1000,
      }),
    };
    
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', options);
      const responseJson = await response.json();
    
      // Store the AI's response in a separate state variable
      const aiResponse = responseJson.choices[0].message.content;

      // Add the user's input and the AI's response to the chat log array
      setChatLog([...chatLog, {user: "me", message: userInput}, {user: "AI", message: aiResponse}])
    } catch (error) {
      setError(error.toString());
    }
    setIsLoading(false);
    scrollToBottom()
  };

  return (
    <div className='bakgrunn'>
      {/* <img src={udir} /> */}
      <div className="supportai-container">
        <div className="supportai-header">
          <div className="supportai-header supportai-nav">
            <h1>SupportAI</h1>
            <p><span>Hei👋, jeg er en robot.<br/></span>Jeg vil svare på alt av spørsmål som har med elevundersøkelsen å gjøre.</p>
          </div>
        </div>
        <div className="supportai-body">
          <div className='supportai-body__typing'>
            {isLoading && <TypingAnimation />}
          </div>
        {chatLog.map((message, index) => {
          if (message.user === 'me') {
            return (
              <div className="message-box me" key={index}>
                <p>{message.message}</p>
              </div>
            );
          } else {
            return (
              <div className="message-box ai" key={index}>
                <p>{message.message}</p>
              </div>
            );
          }
        })}
        </div>
        <div className="supportai-inputarea">
          <form onSubmit={handleSubmit}>
            <input type="text" id="prompt" name="prompt" placeholder='Skriv her...' value={input} onChange={(e) => setInput(e.target.value)}/>
            <button type="submit">
              <svg stroke="currentColor" fill="#333" stroke-width="0" viewBox="0 0 20 20" class="h-4 w-4 rotate-90" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path></svg>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Chatbot;
