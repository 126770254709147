import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { auth } from '../firebaseConfig';

const PrivateRoute = ({ children }) => {
  const authUser = auth.currentUser;

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return authUser ? <Outlet /> : <Navigate to="/" replace />;
};

export default PrivateRoute;
